@import "../../styles/variables";

.link {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 1.8rem 5rem;
    border-radius: 10rem;
    border: none;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.6rem;

    @media only screen and (min-width: $tablet) {
      font-size: 1.8rem;
    }

    @media only screen and (min-width: $laptop) {
      font-size: 2rem;
    }
  }
}
