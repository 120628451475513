html {
  margin: 0;
  padding: 0;
  font-size: 62.5%;

  body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
  }
}
